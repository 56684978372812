
import Vue from 'vue';
import { NIMS_PACKAGE, NIMS_STANDALONE_GOODS, NIMS_TECHNICAL_GOODS } from '@/assets/data/nims/constants';

export default Vue.extend({
  data() {
    return {
      baseURL: process.env.VUE_APP_APPLICATION_URL,
      currentEnv: process.env.VUE_APP_MODE,
      packageId: 49918,
      goodsId: 50089,
      hostingNo: 9993614,
      hostingNo2: 9998651,
      techId: 49945,
      packageObj: NIMS_PACKAGE,
      goodsObj: NIMS_STANDALONE_GOODS,
      techObj: NIMS_TECHNICAL_GOODS,
      modifyGroupNo: 93721,
      modifySeqNo: 1429058,
      paymentSeqNo: 11870091,
    };
  },
  computed: {
    groupLink(): string {
      return `${this.baseURL}/group-select/nims?package=${this.packageId}`;
    },
    groupTechLink(): string {
      return `${this.baseURL}/group-select/nims?package=${this.techId}`;
    },
    groupLinkStandAlone(): string {
      return `${this.baseURL}/group-select/nims?goods_id=${this.goodsId}`;
    },
    nimsStep03Link(): string {
      return `${this.baseURL}/apply-step-03/nims?hst_no=${this.hostingNo}`;
    },
    nimsStep03TechLink(): string {
      return `${this.baseURL}/apply-step-03/nims?hst_no=${this.hostingNo2}&type=tech`;
    },
    nimsModify01Link(): string {
      return `${this.baseURL}/modify-step-01/nims?group=${this.modifyGroupNo}&seq_no=${this.modifySeqNo}`;
    },
    nimsRefunds01Link(): string {
      return `${this.baseURL}/ems-step-01/nims?payment_seqno=${this.paymentSeqNo}`;
    },
  },
  metaInfo: {
    title: '신청 테스트 페이지',
  },
});

// https://temp-application.gabia.com:8080/modify-step-01/nims?group=93721&seq_no=1429058
